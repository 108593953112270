import { Formik, useFormikContext } from "formik";
import {
	initialValues,
	schema,
} from "../../../../../../constants/form/judicial/filters";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import Input from "../../../../../../components/Form/Input";
import { Filters } from "./styles";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Center } from "../../../../../../components/StyledComponents";
import { getAllJudiciales } from "../../../../../../store/actions/judicial";

const AutoSubmit = () => {
	const formik = useFormikContext();

	useEffect(() => {
		if (formik.values !== formik.initialValues) {
			formik.submitForm();
		}
	}, [formik.values]);
	return null;
};

const Component = ({
	judicialStatics,
	judicialStaticsStates,
	setActiveFilters,
}) => {
	const [formSubmmited, setFormSubmmited] = useState(false);
	const [filters, setFilters] = useState<{ [key: string]: string } | null>(
		null
	);
	const dispatch = useDispatch();

	const onSubmit = ({ values, actions }) => {
		Object.keys(values).forEach((key) => {
			if (values[key] === undefined) delete values[key];
		});
		!!setActiveFilters && setActiveFilters(values);
		const filtersSaved = localStorage.getItem("judicial-filters");
		const newFilters = filtersSaved
			? { ...JSON.parse(filtersSaved), ...values }
			: values;
		localStorage.setItem("judicial-filters", JSON.stringify(newFilters));
		dispatch(getAllJudiciales({ page: 0, limit: 10, filters: newFilters }));
	};

	useEffect(() => {
		const filtersSaved = localStorage.getItem("judicial-filters");

		if (filtersSaved) {
			const filtersObj = JSON.parse(filtersSaved);
			setFilters(filtersObj);
		}
	}, []);

	useEffect(() => {
		if (filters) {
			dispatch(getAllJudiciales({ page: 0, limit: 10, filters }));
		}
	}, [filters]);

	return (
		<Filters>
			{/* <span style={{ marginRight: "10px" }}>Filtrar: </span> */}

			<Formik
				initialValues={{ ...initialValues, ...(filters && filters) }}
				onSubmit={(values, actions) => {
					if (onSubmit) onSubmit({ values, actions });
				}}
				validateOnChange={formSubmmited}
				validateOnBlur={false}
				validationSchema={schema}
				enableReinitialize
			>
				{({
					touched,
					errors,
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					resetForm,
					setFieldValue,
					isSubmitting,
				}) => {
					return (
						<form
							className="theme-form"
							onSubmit={(event) => {
								setFormSubmmited(true);
								handleSubmit(event);
							}}
							id="new-judicial-form"
						>
							<Center horizontal="center" vertical="center">
								<ReactSelect
									name="estado"
									error={errors["estado"]}
									touched={touched["estado"]}
									items={[
										{
											label: "Todos estados",
											value: undefined,
										},
										...(judicialStatics?.categories
											? judicialStatics?.categories?.estado?.map((estado) => ({
													label: estado.text,
													value: estado.id,
											  }))
											: []),
									]}
									placeholder="Estado *"
									onChange={handleChange}
									onBlur={handleBlur}
									options={{
										loading: judicialStaticsStates.loading,
										marginBottom: 0,
									}}
									style={{ minWidth: "150px" }}
								/>

								<ReactSelect
									name="responsabilidad"
									error={errors["responsabilidad"]}
									touched={touched["responsabilidad"]}
									items={[
										{
											label: "Todos responsabilidad",
											value: undefined,
										},
										...(judicialStatics?.categories
											? judicialStatics?.categories?.responsabilidad?.map(
													(responsabilidad) => ({
														label: responsabilidad.text,
														value: responsabilidad.id,
													})
											  )
											: []),
									]}
									placeholder="Responsabilidad"
									onChange={handleChange}
									onBlur={handleBlur}
									options={{
										loading: judicialStaticsStates.loading,
										marginBottom: 0,
									}}
									style={{ minWidth: "150px" }}
								/>

								<ReactSelect
									name="compania-2"
									error={errors["compania-2"]}
									touched={touched["compania-2"]}
									items={[
										{
											label: "Todos empleadores",
											value: undefined,
										},
										...(judicialStatics?.categories
											? judicialStatics?.categories["compania-2"]?.map(
													(compania) => ({
														label: compania.text,
														value: compania.id,
													})
											  )
											: []),
									]}
									placeholder="Empleador Real *"
									onChange={handleChange}
									onBlur={handleBlur}
									options={{
										loading: judicialStaticsStates.loading,
										marginBottom: 0,
									}}
									style={{ minWidth: "150px" }}
								/>

								<Input
									name="fecha-recepcion-demanda"
									error={errors["fecha-recepcion-demanda"]}
									touched={touched["fecha-recepcion-demanda"]}
									value={values["fecha-recepcion-demanda"]}
									type="date"
									placeholder="Fecha recepción demanda *"
									onChange={handleChange}
									onBlur={handleBlur}
									options={{
										marginBottom: 0,
									}}
									style={{ minWidth: "150px" }}
								/>

								<ReactSelect
									name="estadoetapa-procesal"
									error={errors["estadoetapa-procesal"]}
									touched={touched["estadoetapa-procesal"]}
									items={[
										{
											label: "Todas etapas",
											value: undefined,
										},
										...(judicialStatics?.categories
											? judicialStatics?.categories[
													"estadoetapa-procesal"
											  ]?.map((etapa) => ({
													label: etapa.text,
													value: etapa.id,
											  }))
											: []),
									]}
									placeholder="Etapa procesal *"
									onChange={handleChange}
									onBlur={handleBlur}
									options={{
										loading: judicialStaticsStates.loading,
										marginBottom: 0,
									}}
									style={{ minWidth: "150px" }}
								/>

								<AutoSubmit />
							</Center>
						</form>
					);
				}}
			</Formik>
		</Filters>
	);
};

const states = ({ judicialStore }) => {
	const { data: judicialStatics, states: judicialStaticsStates } =
		judicialStore.statics;

	return {
		judicialStatics,
		judicialStaticsStates,
	};
};

export default connect(states)(Component);
