import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "../../../../components/Grid";
import { Tag, Card } from "../../../../components/StyledComponents";
import Table from "../../../../components/Table";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import {
	getAllJudiciales,
	getStatics,
} from "../../../../store/actions/judicial";
import Filters from "./components/Filters";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import Tabs from "../../../../components/OtherContent";
import { dateSpliter } from "../../../../helpers/data-handler";
import { useNavigate } from "react-router";
import { argentinaPesos } from "../../../../helpers/loca-currency";
import { useLayout } from "../../../../provider/LayoutProvider";
import Loader from "../../../../components/LogoLoader";
import SearchBar from "../../../../components/SearchBar";

interface ComponentProps {
	judiciales: GetAllJudicialesInDto;
	judicialesStates: {
		loading: boolean;
		success: boolean;
		error: boolean | string;
	};
	judicialStatics;
	judicialStaticsStates: {
		loading: boolean;
		success: boolean;
		error: boolean | string;
	};
}

const Component = ({
	judiciales,
	judicialesStates,
	judicialStatics,
	judicialStaticsStates,
}: ComponentProps) => {
	const { layout, setLayout } = useLayout();
	const { setActions, setFilters } = useActions();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [activeFilters, setActiveFilters] = useState();
	const savedFilters = JSON.parse(
		localStorage.getItem("judicial-filters") || "{}"
	);

	const actions = useMemo(
		(): Action[] => [
			{
				component: "link",
				label: "Nuevo judicial",
				href: "/dashboard/judiciales/new",
				options: {
					type: "filled",
					size: "lg",
					skin: "primary",
				},
			},
		],
		[]
	);

	const columns = useMemo(
		() => [
			{ Header: "Caratula", accessor: "caratula", canSort: true },
			{ Header: "Nro. Expediente", accessor: "nro-expediente" },
			{
				Header: "Naturaleza/Obj",
				Cell: ({ value }) => {
					if (!!value) {
						const item = judicialStatics?.categories?.category?.filter(
							(category) => category.id === value
						)[0];

						return (
							<Tag
								style={{
									...(!!item?.color && { backgroundColor: "#" + item?.color }),
								}}
							>
								{item?.text}
							</Tag>
						);
					}
				},
				accessor: "category",
			},
			{
				Header: "Etapa",
				Cell: ({ value }) => {
					if (!!value) {
						const item = judicialStatics?.categories[
							"estadoetapa-procesal"
						]?.filter((etapa) => etapa.id === value)[0];

						return (
							<Tag
								style={{
									...(!!item?.color && { backgroundColor: "#" + item?.color }),
								}}
							>
								{item?.text}
							</Tag>
						);
					}
				},
				accessor: "estadoetapa-procesal",
			},
			{
				Header: "Recepción Demanda",
				Cell: ({ value }) => {
					if (!!value) {
						const dateSplitted = dateSpliter(value);
						return `${dateSplitted?.day?.number}/${dateSplitted?.month?.number}/${dateSplitted?.year}`;
					}
				},
				accessor: "fecha-recepcion-demanda",
			},
			{
				Header: "Monto Actualizado",
				Cell: ({ value }) => {
					if (!!value) {
						const money = value.toLocaleString("es-AR");
						return argentinaPesos(money, { showCurrency: true });
					}
				},
				accessor: "monto-reclamo-actualizado",
			},
		],
		[judicialStatics]
	);

	const getElement = (row) => {
		navigate(`/dashboard/judiciales/${row.original["item-id"]}`);
	};

	const getElements = ({ page, limit, column, direction }) => {
		try {
			const finalColumn = column || savedFilters?.column;
			const finalDirection = direction || savedFilters?.direction;

			const updatedFilters = {
				...savedFilters,
				...(finalColumn ? { column: finalColumn } : {}),
				...(finalDirection ? { direction: finalDirection } : {}),
			};

			localStorage.setItem("judicial-filters", JSON.stringify(updatedFilters));

			const filters = {
				...(activeFilters ?? {}),
				...updatedFilters,
			};

			dispatch(
				getAllJudiciales({
					page,
					limit,
					filters,
				})
			);
		} catch (error) {
			console.error("Error al manejar los filtros de ordenación:", error);
		}
	};

	const search = (value) => {
		try {
			const updatedFilters = {
				...savedFilters,
				...(value ? { query: value } : { query: undefined }),
			};

			localStorage.setItem("judicial-filters", JSON.stringify(updatedFilters));

			dispatch(
				getAllJudiciales({
					page: 0,
					limit: 10,
					filters: activeFilters,
					query: updatedFilters.query,
				})
			);
		} catch (error) {
			console.error("Error al manejar el filtro de búsqueda:", error);
		}
	};

	useEffect(() => {
		setActions(actions);
		setFilters(<Filters setActiveFilters={setActiveFilters} />);
		!judicialStatics && dispatch(getStatics());
		setLayout({ ...layout, showBackButton: false });
	}, [judiciales]);
	return (
		<Grid.ContainerFluid>
			<Grid.Row>
				<Grid.Col>
					<SearchBar onSearch={(value) => search(value)} />
					<Table
						columns={columns}
						data={judiciales?.hints}
						pagination={judiciales?.pagination}
						onRowClick={(row, index) => getElement(row)}
						fetchData={(data) => getElements(data)}
						loading={judicialesStates?.loading || !judiciales}
					/>
				</Grid.Col>
			</Grid.Row>
		</Grid.ContainerFluid>
	);
};

const states = ({ judicialStore }) => {
	const { data: judiciales, states: judicialesStates } = judicialStore.all;
	const { data: judicialStatics, states: judicialStaticsStates } =
		judicialStore.statics;

	return {
		judicialStatics,
		judicialStaticsStates,
		judiciales,
		judicialesStates,
	};
};

export default connect(states)(Component);
