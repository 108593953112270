import styled from "styled-components";

export const Layout = styled("div")<{ sidebarOpened: boolean }>`
	display: grid;
	gap: 0px;
	grid-template-areas:
		"sidebar header"
		"sidebar subheader"
		"sidebar content";
	grid-template-rows: 60px auto 1fr;
	height: 100vh;
	transition: all 0.5s ease-in-out;

	&.sidebar-closed {
		grid-template-columns: 0px 1fr;
		transition: all 0.5s ease-in-out;
	}

	&.sidebar-opened {
		grid-template-columns: 160px 1fr;
		transition: all 0.5s ease-in-out;
	}
`;

export const Content = styled("main")<{ fullFillContent }>`
	grid-area: content;
	background-color: #f1f4f9;
	padding: ${({ fullFillContent }) => (fullFillContent ? "" : "30px 25px")};
	min-height: 440px;
	max-height: calc(100vh - 60px);
	overflow: auto;
`;

export const Sidebar = styled("aside")`
	grid-area: sidebar;
	//box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 20%);
	overflow-y: auto;
	display: grid;
	grid-template-rows: 95px 1fr;
	background-color: ${({ theme }) => theme.colors.Gray};
`;

export const SubHeader = styled("div")`
	grid-area: subheader;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.White};
	padding: 20px 35px;
	background: #e9edf2;
	border-bottom: 1px solid #dee2e8;
`;
