import { useEffect, useState } from "react";
import slugify from "slugify";
import { useTable, usePagination, useColumnOrder } from "react-table";
import { Center } from "../StyledComponents";
import Loading from "../Loading";
import Icon from "../Icon";
import AngleLeft from "../../assets/icons/angle-left.svg";
import DobleAngleLeft from "../../assets/icons/angle-double-left.svg";
import AngleRight from "../../assets/icons/angle-right.svg";
import DobleAngleRight from "../../assets/icons/angle-double-right.svg";
import { ChevronUp, ChevronDown } from "lucide-react";
import {
	TableContainer,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	LoadingTd,
	TotalItems,
	CurrentPage,
	PageNavigator,
} from "./styles";

interface ComponentProps {
	columns;
	data;
	fetchData?;
	loading?;
	onRowClick?;
	pagination?: {
		offset: number;
		limit: number;
		total: number;
		next: string;
	};
}

const Component = ({
	columns,
	data = [],
	fetchData,
	loading,
	pagination,
	onRowClick,
}: ComponentProps) => {
	const [activeRow, setActiveRow] = useState();
	const [totalPages, setTotalPages] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [columnToSort, setColumnToSort] = useState<string>("");
	const [sortDirection, setSortDirection] = useState<string>("ASC");
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// Get the state from the instance
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: pagination
					? Math.ceil(pagination.offset / pagination.limit)
					: 1,
			}, // Pass our hoisted table state
			manualPagination: true,
			pageCount: pagination
				? Math.ceil(pagination.total / pagination.limit)
				: 1,
		},
		useColumnOrder,
		usePagination
	);

	useEffect(() => {
		fetchData({
			page: currentPage,
			limit: pageSize,
		});
	}, []);

	useEffect(() => {
		if (pagination) {
			setTotalPages(Math.ceil(pagination.total / pagination.limit) || 1);
			setCurrentPage(Math.ceil(pagination.offset / pagination.limit) || 0);
		}
	}, [pagination]);

	useEffect(() => {
		if (pagination && columnToSort !== "" && sortDirection) {
			// in DB is title
			const column = columnToSort === "Título" ? "title" : columnToSort;
			fetchData({
				page: currentPage,
				limit: pageSize,
				column: column.toLowerCase(),
				direction: sortDirection === "ASC" ? "DESC" : "ASC",
			});
		}
	}, [columnToSort, sortDirection]);

	if (!data) {
		return <Loading />;
	}

	return (
		<TableContainer>
			<Table {...getTableProps()}>
				<Thead>
					{headerGroups?.map((headerGroup, headerIndex) => (
						<Tr
							{...headerGroup?.getHeaderGroupProps()}
							key={`thead-${headerIndex}`}
						>
							{headerGroup?.headers.map((column) => (
								<Th {...column?.getHeaderProps()}>
									<div style={{ display: "flex", alignItems: "center" }}>
										{column?.render("Header")}
										{column?.canSort ? (
											<button
												type="button"
												style={{
													cursor: "pointer",
													border: "none",
													background: "none",
													padding: "10px",
												}}
												onClick={() => {
													setColumnToSort(column?.Header);
													setSortDirection(
														sortDirection === "ASC" ? "DESC" : "ASC"
													);
												}}
											>
												{column.Header === columnToSort &&
												sortDirection === "ASC" ? (
													<ChevronUp color="#fff" />
												) : (
													<ChevronDown color="#fff" />
												)}
											</button>
										) : null}
									</div>
								</Th>
							))}
						</Tr>
					))}
				</Thead>

				<Tbody {...getTableBodyProps()}>
					{loading ? (
						<>
							<Tr>
								<LoadingTd colSpan={headerGroups[0].headers.length}></LoadingTd>
							</Tr>
							<Tr>
								<LoadingTd colSpan={headerGroups[0].headers.length}></LoadingTd>
							</Tr>
							<Tr>
								<LoadingTd colSpan={headerGroups[0].headers.length}>
									<Loading />
								</LoadingTd>
							</Tr>
							<Tr>
								<LoadingTd colSpan={headerGroups[0].headers.length}></LoadingTd>
							</Tr>
							<Tr>
								<LoadingTd colSpan={headerGroups[0].headers.length}></LoadingTd>
							</Tr>
							<Tr>
								<LoadingTd colSpan={headerGroups[0].headers.length}></LoadingTd>
							</Tr>
						</>
					) : (
						<>
							{page?.map((row, rowIndex) => {
								prepareRow(row);
								return (
									<Tr
										{...row?.getRowProps()}
										key={`trow-${rowIndex}`}
										className={activeRow === rowIndex ? "active" : ""}
										onClick={() => {
											onRowClick(row, rowIndex);
											setActiveRow(rowIndex);
										}}
									>
										{row?.cells?.map((cell, cellIndex) => {
											return (
												<Td
													key={`trow-${rowIndex}-tcell-${cellIndex}`}
													{...cell?.getCellProps()}
												>
													{cell?.render("Cell")}
												</Td>
											);
										})}
									</Tr>
								);
							})}
						</>
					)}
				</Tbody>

				<Tfoot>
					<Tr>
						<Td colSpan="10000">
							<Center style={{ justifyContent: "space-between" }}>
								<TotalItems>
									{currentPage + 1 === totalPages
										? pagination?.total
										: (currentPage + 1) * (pagination?.limit || 0)}{" "}
									de {pagination?.total} resultados
								</TotalItems>
								{totalPages > 0 && (
									<div className="pagination">
										<Center horizontal="center" vertical="center">
											<PageNavigator
												onClick={() => fetchData({ page: 0, limit: pageSize })}
												disabled={currentPage <= 0}
											>
												<Center horizontal="center" vertical="center">
													<Icon
														icon={DobleAngleLeft}
														size="12px"
														color="black"
													/>
												</Center>
											</PageNavigator>
											<PageNavigator
												onClick={() =>
													fetchData({ page: currentPage - 1, limit: pageSize })
												}
												disabled={currentPage <= 0}
											>
												<Center horizontal="center" vertical="center">
													<Icon icon={AngleLeft} size="12px" color="black" />
												</Center>
											</PageNavigator>
											<PageNavigator
												onClick={() =>
													fetchData({ page: currentPage + 1, limit: pageSize })
												}
												disabled={currentPage + 1 >= totalPages}
											>
												<Center horizontal="center" vertical="center">
													<Icon icon={AngleRight} size="12px" color="black" />
												</Center>
											</PageNavigator>
											<PageNavigator
												onClick={() =>
													fetchData({ page: totalPages - 1, limit: pageSize })
												}
												disabled={currentPage + 1 >= totalPages}
											>
												<Center horizontal="center" vertical="center">
													<Icon
														icon={DobleAngleRight}
														size="12px"
														color="black"
													/>
												</Center>
											</PageNavigator>{" "}
											{/* <span>
                      Ir a la página:{" "}
                      <input
                        type="number"
                        min={1}
                        max={totalPages}
                        defaultValue={currentPage + 1}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) - 1 > 0 &&
                            Number(e.target.value) - 1 < totalPages
                          ) {
                            const page = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;

                            fetchData({ page: page, limit: pageSize });
                          }
                        }}
                        style={{ width: "100px" }}
                      />
                    </span> */}
											<select
												style={{ marginLeft: "10px" }}
												value={pageSize}
												onChange={(e) => {
													setPageSize(Number(e.target.value));
													fetchData({ page: 0, limit: Number(e.target.value) });
												}}
											>
												{[10, 20, 30, 40, 50].map((pageSize) => (
													<option key={`paginado-${pageSize}`} value={pageSize}>
														Mostrar {pageSize}
													</option>
												))}
											</select>
										</Center>
									</div>
								)}
								<CurrentPage>
									{`${currentPage + 1} de ${
										totalPages > 0 ? totalPages : currentPage + 1
									}`}
								</CurrentPage>
							</Center>
						</Td>
					</Tr>
				</Tfoot>
			</Table>
		</TableContainer>
	);
};

export default Component;
