import styled from "styled-components";

const borderSpacing = "1px";
const height = "42px";

export const TableContainer = styled("div")`
	position: relative;
`;

export const Table = styled("table")`
	width: 100%;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	border-spacing: 0 ${borderSpacing};
`;

export const Thead = styled("thead")`
	position: sticky;
	top: ${borderSpacing};
	background-color: ${({ theme }) => theme.colors.Primary};

	tr {
		th {
			color: white;
		}
	}

	&:before {
		content: "";
		width: 100%;
		position: absolute;
		background-color: #f2f4fa;
	}

	&:before {
		bottom: calc(100% - ${borderSpacing});
	}
`;

export const Tbody = styled("tbody")``;

export const Tfoot = styled("tfoot")`
	position: sticky;
	bottom: ${borderSpacing};
	background-color: #f2f4fa;

	tr {
		&:hover {
			& > td {
				background-color: transparent;
				color: ${({ theme }) => theme.colors.Gray};
			}
		}

		td {
			background-color: transparent;
			color: ${({ theme }) => theme.colors.Gray};
			border: 0px;
			border-radius: 5px;
			cursor: default;

			&:first-child {
				border-radius: 5px;
				border-left: 0px;
			}

			&:last-child {
				border-right: 0px;
			}
		}
	}

	&:after {
		content: "";
		height: ${height};
		width: 100%;
		position: absolute;
		background-color: #f2f4fa;
	}

	&:after {
		top: calc(100% - ${borderSpacing});
	}
`;

export const Tr = styled("tr")`
	height: 50px;
	cursor: pointer;

	& > td {
		transition: background-color 0.2s ease-in-out;
		color: ${({ theme }) => theme.colors.Gray};
		background-color: ${({ theme }) => theme.colors.White};
	}

	&.active {
		& > td {
			background-color: ${({ theme }) => theme.colors.LightGray};
			color: ${({ theme }) => theme.colors.Gray};
		}
	}

	&:hover {
		& > td {
			background-color: ${({ theme }) => theme.colors.LightGray};
			color: ${({ theme }) => theme.colors.Gray};
		}
	}
`;

export const Th = styled("th")`
	padding: 0px 10px;

	&:first-child {
		border-radius: 5px 0px 0px 5px;
	}

	&:last-child {
		border-radius: 0px 5px 5px 0px;
	}
`;

export const Td = styled("td")`
	padding: 0px 10px;
	background-color: white;
	border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #cbcbcb;

	&:first-child {
		border-radius: 5px 0px 0px 5px;
		border-left: 1px solid #cbcbcb;
	}

	&:last-child {
		border-radius: 0px 5px 5px 0px;
		border-right: 1px solid #cbcbcb;
	}
`;

export const LoadingTd = styled("td")`
	background-color: transparent !important;
	border-top: 0px solid transparent;
	border-bottom: 0px solid transparent;
`;

const Pagination = styled("div")``;
const PaginationNavButton = styled("button")`
	margin: 0px;
	padding: 0px;
	border: 0px;
	background-color: none;
`;

export const TotalItems = styled("div")`
	min-width: 150px;
	text-align: left;
	flex-shrink: 0;
`;

export const CurrentPage = styled("div")`
	min-width: 150px;
	text-align: right;
	flex-shrink: 0;
`;

export const PageNavigator = styled("button")`
	border: 0px;
	cursor: pointer;
	padding: 0px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	background-color: #e9edf1;

	&:disabled {
		div {
			i:before {
				background-color: #c2c2c2;
			}
		}
	}
`;
