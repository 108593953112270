import { Search } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";

const SearchBarContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: rgb(255, 255, 255);
	border-radius: 6px;
	padding-left: 10px;
	padding-right: 10px;
	width: 500px;
	height: 30px;
	margin-bottom: 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	min-height: 34px;
	transition: 150ms ease-in-out;
`;

const SearchInput = styled.input`
	border: none;
	background: none;
	outline: none;
	flex: 1;
	font-size: 14px;
	color: #333;

	&::placeholder {
		color: #a9a9a9;
	}
`;

const SearchIcon = styled.div`
	cursor: pointer;
	font-size: 16px;
	color: #a9a9a9;
`;

interface SearchBarProps {
	placeholder?: string;
	onSearch: (value: string) => void;
}

const SearchBar = ({ placeholder = "Buscar...", onSearch }: SearchBarProps) => {
	const [searchTerm, setSearchTerm] = useState<string>("");

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			onSearch(searchTerm);
		}
	};

	return (
		<SearchBarContainer>
			<SearchInput
				type="text"
				value={searchTerm}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				placeholder={placeholder}
			/>
			<SearchIcon>
				<Search onClick={() => onSearch(searchTerm)} />
			</SearchIcon>
		</SearchBarContainer>
	);
};

export default SearchBar;
